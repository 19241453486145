import useLanguages from "../../../../hooks/useLanguages";
import Spinner from "../../../../components/Spinner";
import Error from "../../../../components/Alerts/Error";
import useLanguagePairs from "../../../../hooks/useLanguagePairs";

export default function LanguagePair({languagePairId, setLanguagePairId}) {
    const {languagePairs, loading, error} = useLanguagePairs();

    function onChange(evt) {
        setLanguagePairId(evt.target.value);
    }

    return (
        <div className="mb-3">
            {error && <Error message={error} />}
            {loading ? <Spinner />:
                <>
                    <label htmlFor="languagePairId" className={"form-label"}>Language pair:</label>
                    <select id="languagePairId" className={"form-select"} name={"languagePairId"}
                            value={languagePairId} onChange={onChange} required={true}>
                        <option value="">Select a language pair</option>
                        {languagePairs.map(value => {
                            return <option value={value.id} key={value.id}>{value.sourceLanguage} --> {value.targetLanguage}</option>;
                        })}
                    </select>
                </>
            }
        </div>
    );
}