import Container from "../../components/Container";
import ShowRequests from "./ShowRequests";
import AddRequest from "./actions/AddRequest/AddRequest";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import Spinner from "../../components/Spinner";
import CenterContainer from "../../components/CenterContainer";
import {Navigate} from "react-router-dom";

export default function Requests() {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {token, user: {isTranslator}} = useContext(authContext);

    useEffect(() => {
        setLoading(true);
        setError(null);
        let isMounted = true;
        const abortController = new AbortController();

        (async () => {
            try {
                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/requests', {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                });
                if (response.status === 200) {
                    const {requests} = await response.json();
                    isMounted && setRequests(requests);
                }
                else {
                    throw new Error("Wrong status code.");
                }
            }
            catch (e) {
                console.error(e);
                isMounted && setError("Failed to fetch your requests.");
            }
            finally {
                isMounted && setLoading(false)
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, []);

    useEffect(() => {
        let timeoutId = null;
        let isMounted = true;

        const unFinishedRequests = requests.filter(value => value.status !== "Complete" && value.status !== 'Error');

        if (unFinishedRequests.length > 0) {
            console.log("Setting timeout", unFinishedRequests)
            timeoutId = setInterval(async () => {
                await fetchRequests(isMounted, unFinishedRequests);
            }, 5000);
        }



        return () => {
            console.log("Clearing timeout", unFinishedRequests);
            clearInterval(timeoutId);
            isMounted = false;
        }
    }, [requests]);

    async function fetchRequests(isMounted = true, unFinishedRequests) {
        try {
            let searchParams = new URLSearchParams();
            for (const request of  unFinishedRequests) {
                searchParams.append('requestIds', request.id);
            }
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/requests?' + searchParams.toString(), {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            });
            if (response.status === 200) {
                const {requests} = await response.json();
                isMounted && setRequests(prev => {
                        const newRequests = [...prev];
                        for (const request of requests) {
                            newRequests[newRequests.findIndex(value => value.id === request.id)] = request;
                        }
                        return newRequests;
                    });
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function deleteRequest(requestId) {
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/requests/' + requestId, {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + token
                }
            });
            if (response.status === 204) {
                setRequests(prev => {
                    return [...prev].filter(value => value.id !== requestId);
                });
            }
            else {
                throw new Error("Wrong status code.");
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    function addRequest(request){
        setRequests(prev => {
            return [...prev, request];
        });
    }

    return (
        <Container>
            <AddRequest addRequest={addRequest}/>
            <hr />
            {loading ? <CenterContainer><Spinner /></CenterContainer>:
                <ShowRequests deleteRequest={deleteRequest} requests={requests}/>
            }
        </Container>
    )
}