import DeleteRequest from "./actions/DeleteRequest";
import DownloadRequest from "./actions/DownloadRequest";

export default function RequestRow({request, deleteRequest}) {
    return (
        <tr>
            <td>{request.fileName}</td>
            <td>{request.requester}</td>
            <td>{request.sourceLanguage} --> {request.targetLanguage}</td>
            <td>{request.comment}</td>
            <td>{request.status}</td>
            <td className={"text-nowrap"}>
                <DeleteRequest deleteRequest={deleteRequest} request={request}/>
                <DownloadRequest request={request}/>
            </td>
        </tr>
    );

}