import {useState} from "react";
import useBackDrops from "../../../hooks/useBackDrops";
import Spinner from "../../../components/Spinner";

export default function DeleteRequest({request, deleteRequest}) {
    useBackDrops();
    const [loading, setLoading] = useState(false);

    async function onClick() {
        setLoading(true);
        await deleteRequest(request.id);
        setLoading(false);
    }
    const id = `deleteRequest${request.id}`;

    return (
        <>
            <button type="button" className="btn btn-danger btn-sm me-1" data-bs-toggle="modal" data-bs-target={`#${id}`}>
                Delete request
            </button>

            <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={`${id}Label`}>Delete request</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {request.name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" onClick={onClick} disabled={loading}>{loading && <Spinner small={true}/>} Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
