import DownloadRequest from "./actions/DownloadRequest";
import DeleteRequest from "./actions/DeleteRequest";
import RequestRow from "./RequestRow";

export default function ShowRequests({requests, deleteRequest}) {
    return (
        <table className={"table table-striped"}>
            <caption className={"caption-top"}>Requests</caption>
            <thead className={"table-dark"}>
            <tr>
                <th>Filename</th>
                <th>Requester</th>
                <th>Language pair</th>
                <th>Comment</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {requests.map(value => {
                return <RequestRow request={value} deleteRequest={deleteRequest} key={value.id} />;
            })}
            </tbody>
        </table>
    );
}