import Container from "../../components/Container";
import Segment from "./Segment";
import {Navigate} from "react-router-dom";
import {useContext, useRef, useState} from "react";
import {authContext} from "../../context/auth";
import Error from "../../components/Alerts/Error";
import LanguagePair from "../Requests/actions/AddRequest/LanguagePair";
import Spinner from "../../components/Spinner";

export default function Translate() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [request, setRequest] = useState({
        languagePairId: "",
        source: ""
    });
    const [translation, setTranslation] = useState("");

    const {token} = useContext(authContext);

    async function onSubmit(evt) {
        evt.preventDefault();

        setError(null);
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/translate', {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(request)
            });
            if (response.status === 200) {
                const {translation} = await response.json();
                setTranslation(translation);
            }
            else {
                setError("Failed to translate.");
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to create request.");
        }
        finally {
            setLoading(false);
        }
    }

    function setLanguagePairId(languagePairId) {
        setRequest(prev => {
            return {
                ...prev,
                languagePairId
            };
        });
    }

    return (
        <Container>
            {error && <Error message={error}/>}
            <form onSubmit={onSubmit}>
                <LanguagePair languagePairId={request.languagePairId} setLanguagePairId={setLanguagePairId}/>
                <div className="row mb-3">
                    <div className={"col"}>
                        <label htmlFor={"name"} className={"form-label"}>Source:</label>
                        <textarea required={true} className={"form-control"} value={request.source} onChange={evt => {
                            setRequest(prev => {
                                return {
                                    ...prev,
                                    source: evt.target.value
                                };
                            });
                        }}/>
                    </div>
                    <div className={"col"}>
                        <label htmlFor={"name"} className={"form-label"}>Translation:</label>
                        <textarea className={"form-control"} value={translation} readOnly={true} />
                    </div>
                </div>

                <button type={"submit"} className={"btn btn-success w-100"}
                        disabled={loading}>{loading ?
                    <Spinner small={true}/> : 'Translate'}</button>
            </form>
        </Container>
    );
};