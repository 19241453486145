import {useContext, useRef, useState} from "react";
import {authContext} from "../../../../context/auth";
import Spinner from "../../../../components/Spinner";
import Error from "../../../../components/Alerts/Error";
import useCreateCollapse from "../../../../hooks/useCreateCollapse";
import LanguagePair from "./LanguagePair";

export default function AddRequest({addRequest}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [request, setRequest] = useState({
        languagePairId: "",
        comment: ""
    });
    const fileRef = useRef();

    const {token} = useContext(authContext);
    const {hideButtonRef, createButtonRef, collapseRef, open} = useCreateCollapse();

    async function onSubmit(evt) {
        evt.preventDefault();

        setError(null);
        setLoading(true);

        try {
            const formData = new FormData();
            for (const file of fileRef.current.files) {
                formData.append('files', file);
            }
            for (const prop in request) {
                formData.append(prop, request[prop]);
            }
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/requests', {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token
                },
                body: formData
            });
            if (response.status === 201) {
                const {requests} = await response.json();
                for (const request of requests) {
                    addRequest(request);
                }
            }
            else {
                setError("Failed to create request.");
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to create request.");
        }
        finally {
            setLoading(false);
        }
    }

    function setLanguagePairId(languagePairId) {
        setRequest(prev => {
            return {
                ...prev,
                languagePairId
            };
        });
    }

    return (
        <>
            {error && <Error message={error}/>}
            <form onSubmit={onSubmit} id={"addRequestForm"} className={"collapse"} ref={collapseRef}>
                <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">File:</label>
                    <input className="form-control" type="file" id="formFile" ref={fileRef} required={true} multiple={true}/>
                </div>
                <LanguagePair languagePairId={request.languagePairId} setLanguagePairId={setLanguagePairId}/>
                <div className={"mb-3"}>
                    <label htmlFor={"name"} className={"form-label"}>Comment:</label>
                    <textarea className={"form-control"} value={request.comment} onChange={evt => {
                        setRequest(prev => {
                            return {
                                ...prev,
                                comment: evt.target.value
                            };
                        });
                    }}/>
                </div>
            </form>
            <div className={"d-flex"}>
                <button type={"button"} className={"btn btn-secondary me-2  " + (open ? "" : "d-none")}
                        ref={hideButtonRef}>Hide
                </button>
                <button type={open ? "submit" : "button"} ref={createButtonRef} className={"btn btn-success w-100"}
                        form={"addRequestForm"} disabled={loading}>{loading ? <Spinner small={true}/> : 'Add request'}</button>
            </div>
        </>
    );
};
