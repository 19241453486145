import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import {Navigate} from "react-router-dom";
import Spinner from "../../components/Spinner";
import Error from "../../components/Alerts/Error";

export default function SignIn() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const {setUser, setToken, token} = useContext(authContext);

	async function onSubmit(evt) {
		evt.preventDefault();

		try {
			setLoading(true);
			setError(null);

			const response = await fetch(process.env.REACT_APP_ENDPOINT + "/auth/signIn", {
				method: "POST",
				body: JSON.stringify({
					email,
					password
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
			if (response.status === 200) {
				const {user, token} = await response.json();
				setUser(user);
				setToken(token);
			}
			else {
				const {error} = await response.json();
				if (error) {
					setError(error);
				}
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to reach server.");
		}
		finally {
			setLoading(false);
		}
	}

	if (token) {
		return <Navigate to={'/requests'} />;
	}
	return (
		<form onSubmit={onSubmit} className={"shadow-sm border p-3 bg-white m-3 container mx-auto"} >
			{error && <Error message={error} />}

			<div className={"mb-3"}>
				<label htmlFor="email" className={"form-label"}>Email address:</label>
				<input type="email" id={"email"} className={"form-control"} required={true} value={email} onChange={evt => {
					setEmail(evt.target.value);
				}}/>
			</div>
			<div className="mb-3">
				<label htmlFor="password" className={"form-label"}>Password:</label>
				<input type="password" id={"password"} className={"form-control"} required={true} value={password} onChange={evt => {
					setPassword(evt.target.value);
				}}/>
			</div>
			<a href="/forgotPassword">Forgot your password?</a>
			<button type={"submit"} className={"btn btn-success w-100 mt-3"} disabled={loading}>Sign in {loading && <Spinner small={true}/>}</button>

			<p className={"mt-3"}>If you don't have an account yet, sign up <a href="/signUp">here</a>.</p>
		</form>
	);
}
