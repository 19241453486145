import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import Background from "./components/Background";
import Requests from "./pages/Requests";
import Navbar from "./components/Navbar/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SingUp";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import "bootstrap-icons/font/bootstrap-icons.css"
import {AuthProvider} from "./context/auth";
import Translate from "./pages/Translate";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider>

      <BrowserRouter>
              <Routes>
                  <Route element={<><Background /><ProtectedRoute /></>}>
                      <Route path={'/requests'} element={<Requests />}/>
                      <Route path={'/translate'} element={<Translate />}/>
                      <Route path={'*'} element={<Navigate to={"/requests"} />}></Route>
                  </Route>
                  <Route path={'/signIn'} element={<><Background /><SignIn /></>}></Route>
                  <Route path={'/signUp'} element={<><Background /><SignUp /></>}></Route>
                  <Route path={'/resetPassword'} element={<><Background /><ResetPassword /></>}></Route>
                  <Route path={'/forgotPassword'} element={<><Background /><ForgotPassword /></>}></Route>
                  <Route path={'*'} element={<Navigate to={"/signIn"} />}></Route>
              </Routes>
          </BrowserRouter>
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
