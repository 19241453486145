import {useContext, useState} from "react";
import {authContext} from "../../../context/auth";
import {saveAs} from "file-saver";

export default function DownloadRequest({request}) {
    const [loading, setLoading] = useState(false);
    const {token} = useContext(authContext);

    async function download() {
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_ENDPOINT + '/requests/' + request.id + '/download', {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token
                }
            });
            if (response.status === 200) {
                const blob = await response.blob();
                saveAs(blob, 'processed-' + request.fileName);
            }
            else {
                throw new Error("Wrong status code " + response.status);
            }
        }
        catch (e) {
            console.error(e);
        }
        setLoading(false);
    }

    return (
        <button className={"btn btn-success btn-sm"} onClick={download} disabled={request.status !== 'Complete'}>Download</button>
    );
}