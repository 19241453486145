import {createContext, useEffect, useState} from "react";

export const authContext = createContext({
	token: null,
	setToken: (token) => {},
	user: {},
	setUser: (user) => {}
});

export function AuthProvider({children}) {
	const [token, setToken] = useState(null);
	const [user, setUser] = useState({});

	useEffect(() => {
		(async () => {
			const tokenFromLocalStorage = localStorage.getItem('token');
			if (tokenFromLocalStorage) {
				const response = await fetch(process.env.REACT_APP_ENDPOINT + "/auth", {
					method: "GET",
					headers: {
						'Content-Type': 'application/json',
						"Authorization": "Bearer " + token
					}
				});
				if (response.status === 200) {
					const {user} = await response.json();
					setUser(user);
					setToken(tokenFromLocalStorage);
				}
				else {
					const {error} = await response.json();
					if (error) {
						console.error(error);
					}
				}
			}
		})();
	}, []);

	return (
		<authContext.Provider value={{
			token,
			user,
			setToken,
			setUser
		}}>
			{children}
		</authContext.Provider>
	);
}
