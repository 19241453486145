import {useContext, useEffect, useState} from "react";
import {authContext} from "../context/auth";

export default function useLanguagePairs() {
    const [languagePairs, setLanguagePairs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {token} = useContext(authContext);

    useEffect(() => {
        const abortController = new AbortController();
        let isMounted = true;
        setLoading(true);
        setError(null);
        (async () => {

            try {
                const response = await fetch(process.env.REACT_APP_ENDPOINT + '/languagePairs', {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                });
                if (response.status === 200) {
                    const {languagePairs} = await response.json();
                    isMounted && setLanguagePairs(languagePairs);
                }
                else {
                    throw new Error("Wrong status code.");
                }
            }
            catch (e) {
                console.error(e);
                isMounted && setError("Failed to fetch language pairs.");
            }
        })();

        isMounted && setLoading(false);

        return () => {
            abortController.abort();
            isMounted = false;
        }
    }, []);

    return {
        loading,
        languagePairs,
        error
    }
}