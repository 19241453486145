import {NavLink} from "react-router-dom";

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg bg-white shadow-sm border my-3 container">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink to={'/requests'} className={({isActive}) => {
                                return isActive ? 'nav-link active' : 'nav-link'
                            }}>Requests</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/translate'} className={({isActive}) => {
                                return isActive ? 'nav-link active' : 'nav-link'
                            }}>Translate</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};